<template>
  <div>
    <Navbar />
            <!-- ! Main Begin -->
        <main id="main" class="main">
            <section class="home-banner-section inner-banner cover-bg"
              id="banner"
             >
                <div class="banner-caption">
                    <div class="banner-caption-content text-white">
                        <div class="banner-caption-text d-flex w-100 align-items-center flex-warp h-100 py-5">
                            <div class="container">
                                <div class="banner-text">
                                    <h1 class="title-border">Shipping <span>policy</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="curved-section-main">
                <!-- ! Section start -->
                <section class="section curved-section no-min-height">
                    <div class="section-shape section-shape-top">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="84.752" viewBox="0 0 1920 84.752" class="shape-1">
                            <g transform="translate(0 -0.248)">
                                <g transform="translate(0 -402)">
                                    <path
                                        d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                                        fill-rule="evenodd" />
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div class="container section-container-padding ">
                        <h2>Shipping & Delivery Policy</h2>
                        <p class="text-secondary">Last updated October 20, 2021</p>
                        <p>Please carefully review our Shipping & Delivery Policy when purchasing our products. This policy will apply to any order you place with us.</p>
                        <h3>What are my shipping & delivery options?</h3>
                        <h5>Free Shipping</h5>
                        <p>We offer free USPS USA ONLY shipping for USA resident.</p>
                        <h5>Expedited Shipping Fees</h5>
                        <p>We also offer expedited shipping at the following rates:</p>
                        <div class="row">
                            <div class="col-sm-8 col-lg-5 col-xl-4 col-xxl-3">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">Shipping Method</th>
                                            <th scope="col" class="text-center">Shipping Fee</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>USPS</td>
                                            <td class="text-center"></td>

                                        </tr>
                                        <tr>
                                            <td>7 - 10 days</td>

                                            <td class="text-center">0.00</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p>If you select an expedited shipping option, we will follow up after you have placed the order with any additional shipping information.</p>
                        <p>All times and dates given for delivery of the products are given in good faith but are estimates only.</p>
                        <p>For EU and UK consumers: This does not affect your statutory rights. Unless specifically noted, estimated delivery times reflect the earliest available delivery, and deliveries will be made within 30 days after the day we accept
                            your order. For more information please refer to our Terms.</p>
                        <h3>Do you deliver internationally?</h3>
                        <p>We do not offer international shipping.</p>
                        <h3>How can you contact us about this policy?</h3>
                        <p>If you have any further questions or comments, you may contact us by:</p>
                        <p>Email: <a href="mailto:support@vaxtraxglobal.com">support@vaxtraxglobal.com</a></p>
                    </div>
                </section>
                <!-- ! Section end -->
                </div>
                <!-- ! Section start -->
                <section id="lets_talk" class="section curved-section lets-talk-section cover-bg text-white" style="background-image:url(../../assets/images/contact-blockimage.jpg);">
                    <!--   <section class="section curved-section lets-talk-section cover-bg"> -->
                    <div class="container section-container-padding">
                        <div class="section-top-bar d-flex">
                            <div class="section-top-bar-container">
                                <h2 class="section-title title-white">Let's <span>talk</span></h2>
                            </div>

                        </div>
                        <div class="contact-box">
                            <form action="forms/contact.php" method="post" role="form" class="php-email-form h-100">
                                <div class="row">
                                    <div class="col-md-6 form-group mb-4">
                                        <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars">
                                        <div class="validate"></div>
                                    </div>
                                    <div class="col-md-6 form-group mb-4">
                                        <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email">
                                        <div class="validate"></div>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject">
                                    <div class="validate"></div>
                                </div>
                                <div class="form-group mb-4">
                                    <textarea class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                                    <div class="validate"></div>
                                </div>
                                <div class="mb-3">
                                    <div class="loading">Loading</div>
                                    <div class="error-message"></div>
                                    <div class="sent-message">Your message has been sent. Thank you!</div>
                                </div>
                                <div class="text-center"><button class="btn btn-orange" type="submit">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <!-- ! Section end -->

        </main>
        <!-- ! Main end -->
    <!-- <div class="push"></div> -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/screen.css";

#lets_talk {
  background-image: url("../../assets/images/contact-blockimage.jpg") !important;
}
#banner {
  background-image: url("../../assets/images/inneer-banner.jpg") !important;
}
</style>
